import '@shared/assets/styles/index.scss';
import '@shared/assets/styles/global.scss';
import Vue from 'vue';
// import './registerServiceWorker';
import '@shared/config/setenv';
import '@shared/config/constants';
import '@shared/config/polyfills';
import '@shared/config/dompurify';
import '@shared/config/moment';
import '@shared/config/meta';
import '@shared/config/scrollto';
import '@shared/config/vClickOutside';
import '@shared/config/portal';
import '@shared/config/buefy';
import '@shared/config/vuelidate';
import '@shared/config/v_lazy_image';
import '@shared/config/plyr';
import '@shared/config/vueFullscreen';
import '@shared/config/clipboard';
import '@shared/config/socialShare';
import './config/mf_layout';
// import './config/crisp';

// last deps
import router from './config/router';
import store from './config/store';
import './config/analytics';
import './config/errors';
import i18n from './config/i18n';

// router & store deps
import './config/auth';
import './middlewares/routerReferrer';
import './middlewares/godfather';
import './middlewares/auth';
import './middlewares/community';

import './config/global_components';
import App from './App.vue';

import './config/sentry';

Vue.config.productionTip = process.env.NODE_ENV !== 'development';

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

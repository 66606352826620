import { mergeEnvVars } from './services/Utils';

export const isProd = (process.env.NODE_ENV === 'production');
const env = mergeEnvVars(process.env.NODE_ENV, {
  isProd,
  cmsURL: 'https://www.teachizy.fr/wp-json/wp/v2',
  production: {
    rootURL: 'https://teachizyplus.fr',
    rootHost: 'teachizyplus.fr',
    appURL: 'https://app.teachizyplus.fr',
    apiDomain: 'https://api.teachizyplus.fr',
    apiResourcesURL: 'https://api.teachizyplus.fr/storage',
    apiURL: 'https://api.teachizyplus.fr/api/v1',
    stripePK: 'pk_live_bIcP8W3KD9A2l68B6q1RXFZz00B7qZZlDt',
    stripeConnectID: 'ca_H22upTmbMHeHwFeUk2h4M1jDlslBVA6r',
    stripeAIPrices: {
      PACK_S: 'price_1MZ7MFDTshtGyDUGS6ko3iBU',
      PACK_M: 'price_1MZ7MFDTshtGyDUGcknAWW2V',
      PACK_L: 'price_1MZ7MFDTshtGyDUGIwXkBBWX',
    },
  },
  preprod: {
    rootURL: 'https://preprod.teachizyplus.fr',
    rootHost: 'preprod.teachizyplus.fr',
    appURL: 'https://app.preprod.teachizyplus.fr',
    apiDomain: 'https://api.preprod.teachizyplus.fr',
    apiResourcesURL: 'https://api.preprod.teachizyplus.fr/storage',
    apiURL: 'https://api.preprod.teachizyplus.fr/api/v1',
    stripePK: 'pk_test_EFllbbqSbw6gz2KSNeOyI7aV00iC3Ir17c',
    stripeConnectID: 'ca_H22umHcQtMTV34EXhW1DXLlbovE7jdnV',
    stripeAIPrices: {
      PACK_S: 'price_1MXRbHDTshtGyDUGGeKNAYMZ',
      PACK_M: 'price_1MXRbHDTshtGyDUGGWwAALba',
      PACK_L: 'price_1MXRbHDTshtGyDUGcR62J7HF',
    },
  },
  staging: {
    rootURL: 'https://staging.teachizyplus.fr',
    rootHost: 'staging.teachizyplus.fr',
    appURL: 'https://app.staging.teachizyplus.fr',
    apiDomain: 'https://api.staging.teachizyplus.fr',
    apiResourcesURL: 'https://api.staging.teachizyplus.fr/storage',
    apiURL: 'https://api.staging.teachizyplus.fr/api/v1',
    stripePK: 'pk_test_EFllbbqSbw6gz2KSNeOyI7aV00iC3Ir17c',
    stripeConnectID: 'ca_H22umHcQtMTV34EXhW1DXLlbovE7jdnV',
    stripeAIPrices: {
      PACK_S: 'price_1MXRbHDTshtGyDUGGeKNAYMZ',
      PACK_M: 'price_1MXRbHDTshtGyDUGGWwAALba',
      PACK_L: 'price_1MXRbHDTshtGyDUGcR62J7HF',
    },
  },
  development: {
    rootURL: 'http://teachizyplus.localhost',
    rootHost: 'teachizyplus.localhost',
    appURL: 'http://app.teachizyplus.localhost',
    apiDomain: 'http://api.teachizyplus.localhost',
    apiResourcesURL: 'http://api.teachizyplus.localhost/storage',
    apiURL: 'http://api.teachizyplus.localhost/api/v1',
    stripePK: 'pk_test_EFllbbqSbw6gz2KSNeOyI7aV00iC3Ir17c',
    stripeConnectID: 'ca_H22umHcQtMTV34EXhW1DXLlbovE7jdnV',
    stripeAIPrices: {
      PACK_S: 'price_1MXRbHDTshtGyDUGGeKNAYMZ',
      PACK_M: 'price_1MXRbHDTshtGyDUGGWwAALba',
      PACK_L: 'price_1MXRbHDTshtGyDUGcR62J7HF',
    },
  },
});

const contextRoot = env.appURL.includes(window.location.hostname)
  ? '@app'
  : '@school';

env.contextRoot = contextRoot;

export {
  env,
  contextRoot,
};

const isPlainObject = require('lodash/isPlainObject');

const toScssVars = (vars, prefix = '') => {
  let scss = '';
  for (let k in vars) {
    const val = vars[k];
    k = prefix ? `${prefix}_${k}` : k;
    scss += isPlainObject(val) ? toScssVars(val, k) : `
      $${k}: ${val};
    `;
  }
  return scss;
};

const toScssVarMaps = (vars, prefix = '') => {
  let scss = '';
  for (let k in vars) {
    const map = vars[k];
    const val = Object.keys(map)
      .reduce((acc, curr) => (`${acc}'${curr}': ${map[curr]},`), '')
      .replace(/,$/, '');
    k = prefix ? `${prefix}_${k}` : k;
    scss += `
      $${k}: (${val});
    `;
  }
  return scss;
};

const fromTo = (from, to, step = 1) => {
  const result = [];
  for (let i = from; i <= to; i += step) {
    result.push(i);
  }
  return result;
};

const toTheme = (values, keyCallback, valCallback) => values.reduce((acc, val) => {
  acc[
    keyCallback ? keyCallback(val) : val
  ] = valCallback ? valCallback(val) : val;

  return acc;
}, {});

const theme = {
  color: {
    primary: '#EEAC3A',
    primary_hover: '#EEAC3A',
    primary_light: '#d9f6f3',
    secondary: '#A1A1A1',
    third: '#00685A',
    fourth: '#FE805D',
    grey_light: '#DCE5E2',
    grey_light2: '#FFFDF9',
    error: '#AA0000',
    warning: '#FDBB07',
    black: '#000000',
    black_10: 'rgba(0, 0, 0, 0.1)',
    black_30: 'rgba(0, 0, 0, 0.3)',
    black_70: 'rgba(0, 0, 0, 0.7)',
    black_80: 'rgba(0, 0, 0, 0.8)',
    black_05: 'rgba(0, 0, 0, 0.05)',
    white: '#FFFFFF',
    white_dark: '#fafafa',
  },
  fontWeight: {
    400: 400,
    500: 500,
    700: 700,
  },
  fontSize: {
    h1: '60px',
    h2: '44px',
    h3: '36px',
    h4: '26px',
    logo: '20px',
    body: '16px',
    body_big: '18px',
    body_small: '14px',
    body_smallest: '11px',
  },
  radius: {
    4: '4px',
    8: '8px',
    full: '290486px',
  },
  boxShadow: {
    base: {
      size: '0 3px 6px 0',
      color: 'rgba(128,128,128,0.25)',
    },
  },
  component: {
    navbar: {
      height: 64,
      py: 8,
      px: 16,
      mb: 12,
    },
  },
  animations: {
    scale: 1.4,
  },
};

const themeMaps = {
  spacing: {
    ...toTheme(fromTo(0, 32), null, (val) => `${val * 4}px`),
    '1px': '1px',
  },
};

const additionalData = `
  @use 'sass:map';
  @use 'sass:color';
  @use 'sass:string';
  ${toScssVars(theme, 'theme')}
  ${toScssVarMaps(themeMaps, 'theme')}
  @import '~@shared/assets/styles/vars.scss';
`;

module.exports = { ...theme, additionalData };

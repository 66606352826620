<script>
export default {
  props: {
    onNavigate: {
      type: Function,
      default: () => (() => {}),
    },
  },
};
</script>

<template functional>
  <router-link
    :class="[data.staticClass, data.class]"
    v-bind="data.attrs"
    class="logo"
    :to="props.to || '/'"
    @click.native="props.onNavigate"
  >
    <img class="logo_svg mr-3" src="@app/assets/img/logo.svg" width="40" svg-inline>
    <strong class="logo_text is-hidden-touch has-text-black">Teachizy+</strong>
  </router-link>
</template>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  line-height: 0;

  &_svg,
  &_text {
    flex: none;
  }
}
</style>
